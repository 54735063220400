<script>
import axios from "axios";
import Layout from "@/views/layouts/main.vue";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";

/**
 * Allegro Status Component
 */
export default {

  components: {
    Layout
  },

  methods: {
    async fetchAllegroStatus() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const urlParams = Object.fromEntries(urlSearchParams.entries());
      if (!urlParams.code) {
        return
      }

      await this.changeAllegroStatus(urlParams.code, urlParams.error)
    },

    async changeAllegroStatus(code, error) {
      let params = {}
      if (error) {
        params = {
          "code": code,
          "error": error
        }
      } else {
        params = {
          "code": code
        }
      }

      try {
        await axios.get(`/allegro/back`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: params
        });

        Swal.fire("Sukces!", "Konto Allegro zostało pomyślnie dodane!", "success").then(() => this.backTo('/dashboard/user/allegro'))
      } catch (error) {
        Swal.fire("Błąd!", errorCatcher.catchErrors(error), "error").then(() => this.backTo('/dashboard/home'))
      }
    },

    backTo(url) {
      this.$router.push(url).catch((error) => {
        if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
          console.log(error)
        }
      })
    }
  },

  async created() {
    await this.fetchAllegroStatus()
  }

};
</script>

<template>
  <Layout>

  </Layout>
</template>